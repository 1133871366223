import {
    Card,
    CardContent,
    CardHeader, CircularProgress,
    Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider,
    InputAdornment, Stack,
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow,
    TextField
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useCallback, useState} from "react";
import axios from "axios";
import Label from "./components/Label";

const SearchInputWrapper = styled(TextField)(
    ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};

    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(17)};
    }
`
);

const SEARCH_ENDPOINT = "https://europe-west3-yimapp-dev.cloudfunctions.net/checkin-notifier-dev-search";
const CHECKIN_ENDPOINT = "https://europe-west3-yimapp-dev.cloudfunctions.net/checkin-notifier-dev-checkin";

export const Dashboard = () => {
    const [searchValue, setSearchValue] = useState();
    const [searchResults, setSearchResults] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedBookingId, setSelectedBookingId] = useState();
    const [selectedName, setSelectedName] = useState();
    const [isError, setIsError] = useState(false);
    const [isCheckingIn, setIsCheckingIn] = useState(false);

    const onChange = useCallback((event) => {
        setSearchValue(event.target.value);
    }, [])

    const onSearch = useCallback(() => {
        axios.get(`${SEARCH_ENDPOINT}?searchterm=${searchValue}`).then((response) => setSearchResults(response.data));
    }, [setSearchResults, searchValue]);

    const handleOpenCheckinModal = useCallback((bookingId, name) => {
        setDialogOpen(true);
        setSelectedBookingId(bookingId);
        setSelectedName(name);
    }, [setDialogOpen, setSelectedBookingId, setSelectedName]);

    const handleClose = useCallback(() => {
        setDialogOpen(false);
    }, []);

    const handleCheckinSuccessful = useCallback(() => {
        onSearch();
    }, [onSearch]);

    const handleCheckin = useCallback(() => {
        setIsCheckingIn(true);
        axios.post(`${CHECKIN_ENDPOINT}?id=${selectedBookingId}`).then(() => {setIsCheckingIn(false); handleCheckinSuccessful();}).catch(() => setIsError(true));
        setDialogOpen(false);
    }, [selectedBookingId, setIsCheckingIn, setIsError])

    return (
        <Container maxWidth="lg" sx={{ padding: '20px'}}>
            <Card>
                <CardHeader title="Teilnehmer Einchecken" />

                <Divider />
                <CardContent>
                    <Stack display="flex" flexDirection="row" gap="20px">
                        <SearchInputWrapper
                            value={searchValue}
                            autoFocus={true}
                            onChange={onChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchTwoToneIcon />
                                    </InputAdornment>
                                )
                            }}
                            onKeyDown={(ev) => {
                                if (ev.key === 'Enter') {
                                    ev.preventDefault();
                                    onSearch()
                                }
                            }}
                            onBlur={onSearch}
                            placeholder="Name des Teilnehmers"
                            fullWidth
                            label="Suchen"
                        />
                        <Button variant="contained" onClick={onSearch} ><SearchTwoToneIcon sx={{marginRight: "5px"}}/> Suchen</Button>
                    </Stack>

                    <TableContainer sx={{ marginTop: "20px"}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Buchungsnummer</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Minderjährig</TableCell>
                                    <TableCell>Volunteer</TableCell>
                                    <TableCell>CheckIn Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {searchResults.map((result) => (
                                    <TableRow key={result.booking_id}>
                                        <TableCell>
                                            <Typography
                                                variant="body1"
                                                fontWeight="bold"
                                                color="text.primary"
                                                gutterBottom
                                                noWrap
                                            >
                                                {result.booking_id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" color="text.secondary" noWrap>
                                                {result.firstname} {result.lastname}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Stack alignItems="center">
                                                <Label color={result.is_paid ? 'success' : 'error'}>{result.is_paid ? 'Bezahlt': 'Unbezahlt'}</Label>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            {result.is_underage &&
                                                <Stack alignItems="center" gap="5px">
                                                    <Label color="warning">Minderjährig</Label>
                                                    <Typography> Aufsichtsperson: {result.guardian}</Typography>
                                                    <Label color={result.parental_letter_received ? 'success' : 'error'}>{result.parental_letter_received ? 'Einverständniserklärung erhalten' : 'Einverständniserklärung fehlt'}</Label>
                                                </Stack>}
                                            {!result.is_underage &&
                                                <Stack alignItems="center">
                                                    <Label color="success">Volljährig</Label>
                                                </Stack>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {result.volunteer && <Label color="primary">{result.volunteer}</Label>}
                                            {!result.volunteer && "-"}
                                        </TableCell>
                                        <TableCell>
                                            {selectedBookingId === result.booking_id && isCheckingIn && <CircularProgress size={30} disableShrink thickness={3} />}
                                            {!isCheckingIn && result.is_checkedin && <Label color="success">Checked In</Label>}
                                            {! isCheckingIn && !result.is_checkedin && <Button variant="contained" onClick={() => handleOpenCheckinModal(result.booking_id, `${result.firstname} ${result.lastname}`)}>Einckecken</Button>}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {selectedName} einchecken?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Bist du dir sicher, dass du {selectedName} einchecken willst?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Schiessen</Button>
                    <Button onClick={handleCheckin} variant="contained" autoFocus>
                        CheckIn
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>

    );
};